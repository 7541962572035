import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Navbar from "../components/Navbar";
import {
  StoryContainer,
  DescriptionContainer,
  Title,
  Paragraph,
  Highlight,
  Image,
  ImageContainer,
  List,
} from "../styles/StoryStyles";
import StoryIntro from "../components/StoryIntro";
import mango from "../images/m600x337.jpg";
import mango2 from "../images/m600x600.jpg";

const OrangeCat = () => (
  <Layout>
    <SEO title="Orange Cat or Not Orange Cat" />
    <Navbar />
    <StoryContainer>
      <StoryIntro
        image={mango}
        deployUrl="https://vigilant-hugle-84a7b7.netlify.com/"
        repoUrl="https://github.com/MGC3/orange-cat-or-not-client"
        skills="Skills: TensorFlowJS, Keras, Python, React, Javascript, Styled Components, Machine Learning, Deep Learning, Convolutional Neural Networks, Transfer Learning"
      />
      <DescriptionContainer className="fade-in__second">
        <Title>Intro</Title>
        <Paragraph>
          Inspired by the "Not Hotdog" app from HBO's Silicon Valley, I created
          an app that identifies an image as an orange cat...or a not orange
          cat.
        </Paragraph>
        <Paragraph>
          This ended up being a fun side project and was my first introduction
          to the field of machine learning. The story behind how the idea for
          the app first came about can be summarized best as:{" "}
        </Paragraph>
        <List>
          <li>
            Read a random Hacker News article and get interested in machine
            learning. Learn that it can sometimes require large amounts of
            training data.
          </li>{" "}
          <li>Realize that I have over 10,000 pictures of my cat...</li>
          <li>???</li>
          <li>(learn what overfitting is)</li>
          <li>Orange Cat or Not Orange Cat app</li>
        </List>
        <Title>Research</Title>
        <Paragraph>
          This was all completely new territory for me, so I spent plenty of
          time in the research phase before writing any code. I started from the
          ground up and
        </Paragraph>
        <Paragraph>
          End with finding out needed to use transfer learning
        </Paragraph>
        <Title>Training the Model</Title>
        <Paragraph>
          Ooh, name it after me! I barely knew Philip, but as a clergyman I have
          no problem telling his most intimate friends all about him. Is that a
          cooking show? But existing is basically all I do! Who said that? SURE
          you can die! You want to die?!{" "}
          <Highlight>Spare me your space age technobabble</Highlight>, Attila
          the Hun! For one beautiful night I knew what it was like to be a
          grandmother. Subjugated, yet honored. Oh, how awful. Did he at least
          die painlessly? …To shreds, you say. Well, how is his wife holding up?
          …To shreds, you say.
        </Paragraph>
        <Title>Developing the App</Title>
        <Paragraph>
          Ooh, name it after me! I barely knew Philip, but as a clergyman I have
          no problem telling his most intimate friends all about him. Is that a
          cooking show? But existing is basically all I do! Who said that? SURE
          you can die! You want to die?!{" "}
          <Highlight>Spare me your space age technobabble</Highlight>, Attila
          the Hun! For one beautiful night I knew what it was like to be a
          grandmother. Subjugated, yet honored. Oh, how awful. Did he at least
          die painlessly? …To shreds, you say. Well, how is his wife holding up?
          …To shreds, you say.
        </Paragraph>
        <ImageContainer>
          <Image src={mango2} className="fade-in__first" />
        </ImageContainer>
        <Title>What I learned</Title>
        <Paragraph>
          Ooh, name it after me! I barely knew Philip, but as a clergyman I have
          no problem telling his most intimate friends all about him. Is that a
          cooking show? But existing is basically all I do! Who said that? SURE
          you can die! You want to die?!{" "}
          <Highlight>Spare me your space age technobabble</Highlight>, Attila
          the Hun! For one beautiful night I knew what it was like to be a
          grandmother. Subjugated, yet honored. Oh, how awful. Did he at least
          die painlessly? …To shreds, you say. Well, how is his wife holding up?
          …To shreds, you say.
        </Paragraph>
      </DescriptionContainer>
    </StoryContainer>
  </Layout>
);

export default OrangeCat;
